<template>
  <div class="wrap-edit ghtjtzs">
    <FloatTitBtn class="wr">
    <div class="edit-header">
      <span>规划条件通知书</span>
      <span>
      <el-button type="primary" @click="submit" v-if="!readOnly"
        >保存</el-button
      >
      <el-button type="primary" @click="print">打印</el-button>
      </span>
    </div>
    </FloatTitBtn>
    <Ghtjtzsdy id="daYin" :form="form" :YDXZ="YDXZ"></Ghtjtzsdy>
    <div class="edit-container">
      <el-form
        :disabled="readOnly"
        ref="baseform"
        :model="form"
        label-width="130px"
        label-position="left"
      >
        <el-card>
          <template #header>
            <div class="card-header">
              <span>地块概述</span>
            </div>
          </template>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="项目文号:">
                <el-input v-model="form.WH"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="项目名称:">
                <el-input v-model="form.XMMC" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="东:">
                <el-input v-model="form.SZD"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="南:">
                <el-input v-model="form.SZN"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="西:">
                <el-input v-model="form.SZX"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="北:">
                <el-input v-model="form.SZB"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="规划建设用地面积:">
                <el-input
                  v-model="form.GHJSYDZMJ"
                  @change="isGhjsydmjFolded = false"
                  class="input-square"
                />
                
                <strong>（原有建设用地面积 + 新征建设用地面积）</strong>
                <i
                  v-if="isGhjsydmjFolded"
                  class="el-icon-arrow-right arrow"
                  @click="isGhjsydmjFolded = !isGhjsydmjFolded"
                ></i>
                <i
                  v-else
                  class="el-icon-arrow-down arrow"
                  @click="isGhjsydmjFolded = !isGhjsydmjFolded"
                ></i>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24" class="group" v-if="!isGhjsydmjFolded">
            <el-col :span="12">
              <el-form-item label="原有建设用地面积:">
                <el-input
                  v-model="form.YYJSYDMJ"
                  class="input-square"
                />
                
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="新征建设用地面积:">
                <el-input
                  v-model="form.XZJSYDMJ"
                />
                
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="代征公共用地面积:">
                <el-input
                  v-model="form.DZGGYDZMJ"
                  @change="isDzggydmjFolded = false"
                />
                
                <strong
                  >（道路用地面积 + 绿化用地面积 + 河道用地面积 +
                  其他用地面积）</strong
                >
                <i
                  v-if="isDzggydmjFolded"
                  class="el-icon-arrow-right arrow"
                  @click="isDzggydmjFolded = !isDzggydmjFolded"
                ></i>
                <i
                  v-else
                  class="el-icon-arrow-down arrow"
                  @click="isDzggydmjFolded = !isDzggydmjFolded"
                ></i>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24" class="group" v-if="!isDzggydmjFolded">
            <el-col :span="12">
              <el-form-item label="代征道路用地面积:">
                <el-input
                  v-model="form.DZDLMJ"
                />
                
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="代征绿化用地面积:">
                <el-input
                  v-model="form.DZLHMJ"
                />
                
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="代征河道用地面积:">
                <el-input
                  v-model="form.DZHDMJ"
                />
                
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="代征其他用地面积:">
                <el-input
                  v-model="form.DZQTMJ"
                />
                
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="合计用地面积:">
                <el-input
                  v-model="form.HJYDMJ"
                />
                
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <br />
        <el-card>
          <template #header>
            <div class="card-header">
              <span>规划控制指标</span>
            </div>
          </template>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-radio-group v-model="YDXZ" @change="switchYdxz">
                <el-radio-button label="dy">单一用地</el-radio-button>
                <el-radio-button label="hh">混合用地</el-radio-button>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row :gutter="24" v-if="YDXZ == 'dy'">
            <el-col :span="24">
              <el-form-item label="单一用地性质:">
                <el-input v-model="form.YDXZDM"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <template v-if="YDXZ == 'hh'">
            <el-row :gutter="24" v-for="(_, index) in hhydxzs" :key="_">
              <el-col :span="12">
                <el-form-item :label="'混合用地性质' + (index + 1) + ':'">
                  <el-input v-model="form['HHYDXZDM' + (index + 1)]"></el-input>
                  <button
                    v-if="hhydxzs.length < 3 && index == 0"
                    type="button"
                    class="add-button"
                    @click="addHhydxz"
                    :style="{ 'pointer-events': readOnly ? 'none' : 'auto' }"
                  >
                    <i class="el-icon-plus"></i>
                  </button>
                  <button
                    v-else-if="hhydxzs.length - 1 == index && index > 0"
                    type="button"
                    class="remove-button"
                    @click="removeHhydxz(index)"
                    :style="{ 'pointer-events': readOnly ? 'none' : 'auto' }"
                  >
                    <i class="el-icon-minus"></i>
                  </button>
                  <button
                    v-else
                    type="button"
                    class="placeholder-button"
                  ></button>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="'混合比例' + (index + 1) + ':'">
                  <el-input
                    v-model="form['HHYDXZBL' + (index + 1)]"
                    controls-position="right"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </template>
          <el-row :gutter="24">
             <el-col :span="24">
              <el-form-item label="用地性质（注）:">
                <el-input
                  v-model="form.YDXZZ"
                  type="textarea"
                  :rows="2"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="容积率:">
                <el-input
                  v-model="form.RJLXX"
                />
              </el-form-item>
              <el-form-item label="建筑密度(%):">
                <el-input
                  v-model="form.JZMDXX"
                />
              </el-form-item>
              <el-form-item label="建筑高度(m):">
                <el-input
                  v-model="form.JZGDXX"
                />
              </el-form-item>
              <el-form-item label="绿地率(%):">
                <el-input
                  v-model="form.LDLXX"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="建筑层数:">
                <el-input v-model="form.JZCS"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="退让规划用地边界红线距离:">
                <el-input
                  v-model="form.TRGHYDBJHX"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="退让规划道路红线距离:">
                <el-input
                  v-model="form.TRGHDLHX"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="退让规划河道蓝线距离:">
                <el-input
                  v-model="form.TRGHHDLX"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="地下建筑退让规划用地边界红线距离:">
                <el-input
                  v-model="form.DXJZTRGHYDBJHX"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="围墙退让距离:">
                <el-input
                  v-model="form.WQTRJL"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="其他退让距离:">
                <el-input
                  v-model="form.QTTRJL"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-col>
             <el-col :span="24">
              <el-form-item label="建筑退让（注）:">
                <el-input
                  v-model="form.JZTRZ"
                  type="textarea"
                  :rows="3"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="建筑间距:">
                <el-input
                  v-model="form.JZJJ"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="其他间距要求:">
                <el-input
                  v-model="form.QTJJYQ"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="竖向设计:">
                <el-input
                  v-model="form.SXSJ"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="日照分析:">
                <el-input
                  v-model="form.RZFX"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="技术经济指标复核:">
                <el-input
                  v-model="form.JSJJZBFH"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="其他:">
                <el-input
                  v-model="form.QT2"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <br />
        <el-card>
          <template #header>
            <div class="card-header">
              <span>配套设施</span>
            </div>
          </template>
          <el-row :gutter="24">
            <el-col :span="24">
            <el-form-item label="物业管理用房:">
                <strong>不小于地上总建筑面积的（单位%）</strong>
                <el-input
                  v-model="form.WYGLYF"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
            <el-form-item label="物业经营用房:">
                <strong>不小于地上总建筑面积的（单位%）</strong>
                <el-input
                  v-model="form.WYJYYF"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <template v-for="(item,index) in formData3" :key="index">
                <el-form-item :label="item.label">
                <el-input
                  v-model="form[item.value]"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
              </template>
            </el-col>
            <el-col :span="24">
            <el-form-item label="注:">
                <el-input
                  v-model="form.ZHU3"
                  type="textarea"
                  :rows="3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <br />
        <el-card>
          <template #header>
            <div class="card-header">
              <span>交通组织</span>
            </div>
          </template>
           <el-row :gutter="24">
            <el-col :span="24">
              <template v-for="(item,index) in formData4" :key="index">
                <el-form-item :label="item.label">
                <el-input
                  v-model="form[item.value]"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
              </template>
            </el-col>
          </el-row>
        </el-card>
        <br />
        <el-card>
          <template #header>
            <div class="card-header">
              <span>城市设计要求</span>
            </div>
          </template>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="建筑设计:">
                <el-input
                  v-model="form.JZSJ"
                  type="textarea"
                  :rows="5"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="城市设计:">
                <el-input
                  v-model="form.CSSJ"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="专项设计:">
                <el-input
                  v-model="form.ZXSJ"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="环境景观:">
                <el-input
                  v-model="form.HJJG"
                  type="textarea"
                  :rows="4"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <br />
        <el-card>
          <template #header>
            <div class="card-header">
              <span>其他要求</span>
            </div>
          </template>
          <el-row :gutter="24">
          <el-col :span="24">
              <el-form-item label="其他要求:">
                <el-input
                  v-model="form.QTYQ"
                  type="textarea"
                  :rows="8"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <br />
        <el-card>
          <template #header>
            <div class="card-header">
              <span>注意事项</span>
            </div>
          </template>
          <el-row :gutter="24">
          <el-col :span="24">
              <el-form-item label="注意事项:">
                <el-input
                  v-model="form.ZYSX"
                  type="textarea"
                  :rows="5"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <br />
        <el-card>
          <template #header>
            <div class="card-header">
              <span>附件</span>
            </div>
          </template>
          <el-row :gutter="24">
          <el-col :span="24">
              <el-form-item label="附件:">
                <el-input
                  v-model="form.FJ"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-col>
          <el-col :span="12">
          </el-col>
          <el-col :span="12">
              <el-form-item label="日期:">
                <el-date-picker
                    style="width: 100%"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    v-model="form.JBRQ"
                    placeholder="请选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import mgr from "@/services/security.js";
import FloatTitBtn from '../../components/FloatTitBtn.vue';
import print from "print-js";
import Ghtjtzsdy from "./ghtjtzsdy";
export default {
  name:'ghtjtzs',
  components: { FloatTitBtn,Ghtjtzsdy },
  data() {
    return {
      readOnly: true,
      ydxzMap: {
        codeA: "用地性质A",
        codeB: "用地性质B",
        codeC: "用地性质C",
      },
      isDzggydmjFolded: false,
      isGhjsydmjFolded: false,
      YDXZ: "dy",
      form: {
        Id: "",
        BJID: "",
        WH: "",
        XMMC: "",
        SZD: "",
        SZN: "",
        SZX: "",
        SZB: "",
        GHJSYDZMJ: undefined,
        YYJSYDMJ: undefined,
        XZJSYDMJ: undefined,
        DZGGYDZMJ: undefined,
        DZDLMJ: undefined,
        DZLHMJ: undefined,
        DZHDMJ: undefined,
        DZQTMJ: undefined,
        HJYDMJ: undefined,
        YDXZ: "",
        YDXZDM: "",
        HHYDXZXZ1: "",
        HHYDXZDM1: "",
        HHYDXZBL1: undefined,
        HHYDXZXZ2: "",
        HHYDXZDM2: "",
        HHYDXZBL2: undefined,
        HHYDXZXZ3: "",
        HHYDXZDM3: "",
        HHYDXZBL3: undefined,
        YDXZZ: "",
        RJLSX: undefined,
        RJLXX: undefined,
        JZMDSX: undefined,
        JZMDXX: undefined,
        JZGDSX: undefined,
        JZGDXX: undefined,
        LDLSX: undefined,
        LDLXX: undefined,
        JZCS: "",
        TRGHYDBJHX: "",
        TRGHDLHX: "",
        TRGHHDLX: "",
        DXJZTRGHYDBJHX: "",
        WQTRJL: "",
        QTTRJL: "",
        JZTRZ: "",
        JZJJ: "",
        QTJJYQ: "",
        SXSJ: "",
        RZFX: "",
        JBR: "",
        JSJJZBFH: "",
        QT2: "",
        WYGLYF: "",
        WYJYYF: "",
        SQYF: "",
        YLFWYF: "",
        SZYT: "",
        QT3: "",
        ZHU3: "",
        CRKSZ1: "",
        CRKSZ2: "",
        TCBW1: "",
        TCBW2: "",
        TCBW3: "",
        TCBW4: "",
        TCFS: "",
        JTZZ: "",
        JTFX: "",
        JZSJ: "",
        CSSJ: "",
        ZXSJ: "",
        HJJG: "",
        QTYQ: "",
        ZYSX: "",
        FJ: "",
        JBRQ: new Date()
      },
      formData3:[
        {label:'社区用房:',value:'SQYF'},
        {label:'养老服务用房:',value:'YLFWYF'},
        {label:'市政依托:',value:'SZYT'},
        {label:'其他:',value:'QT3'},
        ],
      formData4:[
        {label:'机动车主要出入口:',value:'CRKSZ1'},
        {label:'人行出入口:',value:'CRKSZ2'},
        {label:'小型汽车停车位:',value:'TCBW1'},
        {label:'上落客货车停车位:',value:'TCBW2'},
        {label:'非机动车停车位:',value:'TCBW3'},
        {label:'地面停车位数量要求:',value:'TCBW4'},
        {label:'停车泊位（注）:',value:'ZHU4'},
        {label:'停车方式:',value:'TCFS'},
        {label:'交通组织:',value:'JTZZ'},
        {label:'交通分析:',value:'JTFX'},],
      hhydxzs: [Date.now()],
    };
  },
  methods: {
    print() {
      print({
        printable: "daYin",
        type: "html",
        scanStyles: false,
        css: "/static/print-style.css",
        documentTitle: "",
      });
    },
    async fetch() {
      try {
        await this.$ajax
          .get(
            this.$appConfig.apiUrls.ywxtApi +
              "/api/YW/Getghtjtzs?BJID=" +
              this.form.BJID
          )
          .then((response) => {
            if (response.data.IsSuccess) {
              this.form = response.data.Data;
              if(this.form.Id==null||this.form.Id==undefined){
                this.form.YDXZZ='单一居住用地允许配置计容建筑面积小于总计容建筑面积10%的“三房”及商业配套用房。'
                this.form.JZTRZ='以上未尽事宜应按《慈溪市城乡规划管理技术规定》进行退让。并应满足景观环境、消防、交通、卫生防疫、环境保护、工程管线、人防疏散、建筑保护和施工安全等方面要求。'
                this.form.DXJZTRGHYDBJHX='地下室离界距离不小于地下室深度的0.7倍，且不小于5米，其自用管线和支护结构的外侧的离界距离不小于1米。'
                this.form.WQTRJL='围墙后退道路边线不小于1.5米。'
                this.form.SXSJ='根据周边道路规划标高做好竖向设计，并与周边地块做好衔接。'
                this.form.RZFX='按相关规定执行。'
                this.form.JSJJZBFH='按相关规定执行。'
                this.form.SZYT='给水、雨水、供电、燃气、通讯等各类相关管线应接入市政管网。污水应达标后排入市政管网。'
                this.form.ZHU3='（1）人防设施、垃圾转运站等按相关部门要求配建。（2）地块内涉及河道填埋须经市水利部门批准，地块内涉及电力线及其他管线的迁移需与相关管线单位对接后方能实施。'
                this.form.TCFS='应配建地下停车场。'
                this.form.JTFX='充分考虑人群集散。'
                this.form.JZSJ='1、地块应统一规划、合理布局，处理好与周边地块的关系，尤其应处理好与     的关系。\n2、应注重    道路（街道）城市界面，做好城市空间景观设计。\n3、建筑风格、体量、色彩宜简洁大方，体现特色，并与周边建筑相协调。\n4、空调外架、冷凝水落水管与雨水管等建筑附属设施细部应在建筑设计中统一协调设置，确保建筑立面的整体性。\n5、做好建筑节能设计，鼓励使用环保、节能的新型建筑材料，外墙材料应具有耐久性。'
                this.form.HJJG='1、沿河景观：\n2、沿路景观：\n3、绿化景观：\n4、其他：'
                this.form.QTYQ='1、规划设计方案提交我局审查时，须提供设计方案一式    份。\n2、拟建建设项目的建筑设计须满足国家相关建筑设计规范。\n3、拟建建设项目应同时满足节能、环保、消防、人防、气象、建设、市政、园林、交通、文物、保密、通讯、水利、教育、体育等各项法规、规章、规范、规定的要求，并按有关规定与有关行政主管部门联系。\n4、设计深度需符合《建筑工程设计文件编制深度要求》。\n5、建筑面积及容积率应按浙江省工程建设标准《建筑工程建筑面积计算和竣工综合测量技术规程》执行。\n6、划拨地块规划条件有效期同选址意见书为一年。\n7、出让地块规划条件自核发之日起一年内未依法出让成交的，需重新确认。自出让地块成交之日起二年内未正式提交规划设计方案的，原规划条件作废。\n8、土地出让后，若相关城乡规划、法律、法规、规范等有重大调整，应对本规划条件作相应调整。\n9、其他未尽事宜按相关法律、法规、规章、规范性文件、有关建筑设计规范及该区域的控规等执行。\n10、其他：'
                this.form.ZYSX='1、声明：在满足本规划条件规定的建筑间距、建筑后退、日照要求、停车位配建、绿地率等要求的前提下，由于总平面布局的多样性和不确定性，并考虑到不可预见因素，容积率、建筑密度、建筑高度等指标不一定都能达到本规划条件出具的上限数值。建设单位应在充分研究地块相关情况的基础上，充分考虑由于容积率、建筑密度、建筑高度等指标可能做不到上限数值带来的风险。\n2、规划设计方案在满足本规划条件上述要求的同时，还应满足《慈溪市城乡规划管理技术规定》及城市设计要求。\n3、本规划条件解释权归慈溪市自然资源和规划局。'
                this.form.FJ='本条件书附图一份。'
                var c = (new Date()).toLocaleDateString();
                var d=c.split("/");
                if(d[1]<10){
                  d[1]='0'+d[1]
                }
                this.form.JBRQ=d.join('-');
              }
            } else {
              throw response.data.ErrorMessage;
            }
          });
        const user = await mgr.getUser();
        if (this.form.JBR === user.profile.sub && this.$route.query.taskId) {
          this.readOnly = false;
          this.isDzggydmjFolded = true;
          this.isGhjsydmjFolded = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      let me = this;
      // if (this.checkForm()) {
        console.log("AddOrUpdateghtjtzs");
        try {
          await this.$ajax
            .post(
              this.$appConfig.apiUrls.ywxtApi + "/api/YW/AddOrUpdateghtjtzs",
              this.form
            )
            .then(function (response) {
              if (response.data.IsSuccess) {
                me.form.Id = response.data.Data;
                ElMessage({
                  type: "success",
                  message: "保存成功!",
                });
              } else {
                ElMessage({
                  type: "info",
                  message: "保存失败!",
                });
                console.log(response.data.ErrorMessage);
              }
            });
        } catch (error) {
          console.log(error);
        }
      // }
    },
    addHhydxz() {
      this.hhydxzs.push(Date.now());
    },
    removeHhydxz(index) {
      this.hhydxzs.splice(index, 1);
      this.form["HHYDXZBL" + (index + 1)] = undefined;
      this.form["HHYDXZDM" + (index + 1)] = "";
    },
    switchYdxz(label) {
      if (label == "dy") {
        for (let i = 1; i <= 3; i++) {
          this.form["HHYDXZBL" + i] = undefined;
          this.form["HHYDXZDM" + i] = null;
        }
        this.hhydxzs = [Date.now()];
      } else if (label == "hh") {
        this.form.YDXZDM = "";
      }
    },
    checkInput(val, mess) {
      if (!val) {
        ElMessage({
          type: "info",
          message: "请输入" + mess,
        });
        return false;
      } else {
        return true;
      }
    },
    checkForm() {
      let b = true;
      let f = this.form;
      if (b) b = this.checkInput(f.WH, "项目文号");
      if (b) b = this.checkInput(f.HJYDMJ, "合计用地面积");
      if (b) b = this.checkInput(f.XMMC, "项目名称");
      return b;
    },
  },
  mounted() {
    this.form.BJID = this.$route.query.instanceId ?? "";
    this.fetch();
  },
  watch: {
    "form.YDXZDM": {
      handler: function (newCode) {
        this.form.YDXZ = this.ydxzMap[newCode];
      },
    },
    "form.HHYDXZDM1": {
      handler: function (newCode) {
        if (newCode) {
          this.YDXZ = "hh";
        }
        this.form.HHYDXZXZ1 = this.form.HHYDXZDM1;
      },
    },
    "form.HHYDXZDM2": {
      handler: function (newCode) {
        while (newCode && this.hhydxzs.length < 2) {
          this.hhydxzs.push(Date.now());
        }
        this.form.HHYDXZXZ2 = this.form.HHYDXZDM2;
      },
    },
    "form.HHYDXZDM3": {
      handler: function (newCode) {
        while (newCode && this.hhydxzs.length < 3) {
          this.hhydxzs.push(Date.now());
        }
        this.form.HHYDXZXZ3 = this.form.HHYDXZDM3;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.wr{
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 0px;
}
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrap-edit .edit-header {
  margin-bottom: 15px;
}

.el-input,
.el-textarea,
.el-input-number {
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
}


.group {
  border: 1.5px solid rgb(103, 194, 58);
  border-radius: 4px;
  background-color: rgb(230, 255, 230);
}

.input-width {
  flex: 1;
}

.add-button {
  border-radius: 50%;
  width: 38px;
  height: 38px;
  margin-left: 10px;
  border: none;
  background-color: rgb(103, 194, 58);
  color: white;
  font-size: 1.5em;
  cursor: pointer;
  &:hover {
    background-color: rgb(133, 206, 97);
  }
  i {
    font-weight: bold;
  }
}

.remove-button {
  border-radius: 50%;
  width: 38px;
  height: 38px;
  margin-left: 10px;
  border: none;
  background-color: rgb(245, 108, 108);
  color: white;
  font-size: 1.5em;
  cursor: pointer;
  &:hover {
    background-color: rgb(247, 137, 137);
  }
  i {
    font-weight: bold;
  }
}

.placeholder-button {
  width: 38px;
  height: 38px;
  margin-left: 10px;
  background-color: transparent;
  border: none;
}

.el-radio-group {
  margin-bottom: 10px;
}


.arrow {
  color: rgb(103, 194, 58);
  font-size: 1.5em;
  font-weight: bold;
  cursor: pointer;
}
</style>

<style lang="scss">
.ghtjtzs {
  .el-form-item__content {
    display: flex;
    align-items: center;
  }

  input[min] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
