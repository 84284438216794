<template>
  <div class="wrap" style="font-family: 'SimSun';">
    <h1 style="text-align: center;font-size:24px;margin-top:65px;text-shadow:0.5pt 0px 0px black,0.15pt 0px 0px black,0.25pt 0px 0px black;">
      <div class="wrap-underline">{{form.XMMC}}</div>地块（项目）规划条件通知书
    </h1>
    <p style="float: right; margin-right: 240px">编号：{{form.WH}}</p>
    <table width="100%" cellspacing="1" cellpadding="4" class="tabtop13">
      <tr>
        <td rowspan="6" class="btbg1 font-center" style="width: 30px"><div>一、地块概况</div></td>
        <td class="btbg2">地块（项目）名称</td>
        <td colspan="5" >{{form.XMMC}}</td>
      </tr>
      <tr>
        <td class="btbg2">用地位置、范围（另详见附图）</td>
        <td colspan="5">
          <span
            >东：
            <div class="wrap-underline" style="width: 440px">{{form.SZD}}</div></span
          >
          <br /><span
            >南：
            <div class="wrap-underline" style="width: 440px">{{form.SZN}}</div
          ></span>          
          <br /><span
            >西：
            <div class="wrap-underline" style="width: 440px">{{form.SZX}}</div
          ></span>
          <br /><span
            >北：
            <div class="wrap-underline" style="width: 440px">{{form.SZB}}</div
          ></span>
        </td>
      </tr>
      <tr>
        <td rowspan="2" class="btbg2">规划建设用地面积</td>
        <td colspan="2" rowspan="2">
          约
          <div class="wrap-underline">{{form.GHJSYDZMJ}}</div>
          （以实测为准。该面积为计算经济指标技术的基数）
        </td>
        <td rowspan="2" class="zi-center" style="width:50px">其中</td>
        <td colspan="2">
          原有建设占地面积约：
          <div class="wrap-underline">{{form.YYJSYDMJ}}</div>
          
        </td>
      </tr>
      <tr>
        <td colspan="5">
          新征（占）建设占地面积约：
          <div class="wrap-underline">{{form.XZJSYDMJ}}</div>
          
        </td>
      </tr>
      <tr>
        <td class="btbg2">另代征公共用地面积</td>
        <td colspan="2">
          约
          <div class="wrap-underline">{{form.DZGGYDZMJ}}</div>
          （以实测为准。）
        </td>
        <td class="zi-center">其中</td>
        <td colspan="2">
          <span
            >代征道路用地面积约:
            <div class="wrap-underline">{{form.DZDLMJ}}</div>
            </span
          ><br />
          <span
            >代征绿化用地面积约:
            <div class="wrap-underline">{{form.DZLHMJ}}</div>
            </span
          ><br />
          <span
            >代征河道用地面积约:
            <div class="wrap-underline">{{form.DZHDMJ}}</div>
            </span
          ><br />
          <span
            >代征其他用地面积约:
            <div class="wrap-underline">{{form.DZQTMJ}}</div>
            </span
          >
        </td>
      </tr>
      <tr>
        <td class="btbg2">合计用地面积</td>
        <td colspan="5">
          约
          <div class="wrap-underline">{{form.HJYDMJ}}</div>
          （以实测为准。）
        </td>
      </tr>
      <!-- 第二部分 -->
      <tr>
        <td :rowspan="11 + checkH()" class="btbg1 font-center"><div>二、规划控制指标</div></td>
        <td :rowspan="2 + checkH()" class="btbg2">用地性质</td>
        <td style="width: 115px" class="zi-center">单一用地性质</td>
        <td colspan="4">{{form.YDXZDM ? form.YDXZDM:'/'}}</td>
      </tr>
      <tr>
        <td class="zi-center">混合用地性质{{form.HHYDXZXZ1!=null && form.HHYDXZXZ2!=undefined?'1':''}}</td>
        <td style="width:300px;" colspan="2">{{form.HHYDXZXZ1 ? form.HHYDXZXZ1:'/'}}</td>
        <td class="zi-center" style="width:100px;">混合比例{{ form.HHYDXZXZ1 != null && form.HHYDXZXZ2!=undefined?'1':''}}</td>
        <td>{{ form.HHYDXZXZ1 != null && form.HHYDXZXZ1 != undefined ? form.HHYDXZBL1:'/'}}</td>
      </tr>
      <tr v-if="form.HHYDXZXZ2 != null && form.HHYDXZXZ2 != undefined && YDXZ == 'hh'">
        <td class="zi-center">混合用地性质2</td>
        <td style="width:300px;" colspan="2">{{form.HHYDXZXZ2}}</td>
        <td class="zi-center" style="width:100px;">混合比例2</td>
        <td>{{form.HHYDXZBL2}}</td>
      </tr>
      <tr v-if=" form.HHYDXZXZ3 != null && form.HHYDXZXZ3 != undefined && YDXZ == 'hh'">
        <td class="zi-center">混合用地性质3</td>
        <td style="width:300px;" colspan="2">{{form.HHYDXZXZ3}}</td>
        <td class="zi-center" style="width:100px;">混合比例3</td>
        <td>{{form.HHYDXZBL3}}</td>
      </tr>
      <tr>
        <td colspan="5" class="wrap-zs" style="height:80px!important;">注：{{form.YDXZZ}}</td>
      </tr>
      <tr>
        <td class="btbg2">容积率</td>
        <td colspan="2">{{form.RJLXX}}</td>
        <td class="zi-center" style="width:70px;">绿地率</td>
        <td colspan="2">{{form.LDLXX}}</td>
      </tr>
      <tr>
        <td class="btbg2">建筑密度</td>
        <td colspan="2">{{form.JZMDXX}}</td>
        <td class="zi-center">建筑高度</td>
        <td colspan="2">{{form.JZGDXX}}</td>
      </tr>
      <tr>
        <td class="btbg2">建筑层数</td>
        <td colspan="5">{{form.JZCS}}</td>
      </tr>
      <tr>
        <td class="btbg2" rowspan="2">建筑退让（详见地块控制图）</td>
        <td colspan="5" style="height:70px;">退让规划用地边界红线距离：{{form.TRGHYDBJHX}}</td>
      </tr>
      <tr>
        <td colspan="5">退让规划道路红线距离：{{form.TRGHDLHX}}</td>
      </tr>
      
    </table>
    <div style="page-break-after: always"></div>
    <table width="100%" cellspacing="1" cellpadding="4" class="tabtop13">
      <tr>
        <td rowspan="11" class="btbg1 font-center" style="width: 30px"></td>
        <td colspan="1" rowspan="5"></td>
        <td colspan="5">退让规划河道蓝线距离：{{form.TRGHHDLX}}</td>
      </tr>
      <tr>
        <td colspan="5">地下建筑退让规划用地边界红线距离：{{form.DXJZTRGHYDBJHX}}</td>
      </tr>
      <tr>
        <td colspan="5">围墙退让距离：{{form.WQTRJL}}</td>
      </tr>      
      <tr>
        <td colspan="5">其他退让距离：{{form.QTTRJL}}</td>
      </tr>
      <tr>
        <td colspan="5" class="wrap-zs" style="height:100px!important;">注：{{form.JZTRZ}}</td>
      </tr>
      <tr>
        <td class="btbg2" rowspan="2">建筑间距</td>
        <td colspan="5">{{form.JZJJ}}&nbsp;</td>
      </tr>
      <tr>
        <td colspan="5">其他间距要求：{{form.QTJJYQ}}</td>
      </tr>
      <tr>
        <td class="btbg2">竖向设计</td>
        <td colspan="5">{{form.SXSJ}}</td>
      </tr>
      <tr>
        <td class="btbg2">日照分析</td>
        <td colspan="5">{{form.RZFX}}</td>
      </tr>
      <tr>
        <td class="btbg2">技术经济指标复核</td>
        <td colspan="5">{{form.JSJJZBFH}}</td>
      </tr>
      <tr>
        <td class="btbg2">其他</td>
        <td colspan="5">{{form.QT2}}</td>
      </tr>
      <!-- 第三部分 -->
      <tr>
        <td rowspan="7" class="btbg1 font-center"><div>三、配套设施</div></td>
        <td class="btbg2">物业管理用房</td>
        <td colspan="5">
          不小于地上总建筑面积的
          <div class="wrap-underline">{{form.WYGLYF}}<span v-show="form.WYGLYF>0">%</span></div>
          。
        </td>
      </tr>
      <tr>
        <td class="btbg2">物业经营用房</td>
        <td colspan="5">
          不小于地上总建筑面积的
          <div class="wrap-underline">{{form.WYJYYF}}<span v-show="form.WYJYYF>0">%</span></div>
          。
        </td>
      </tr>
      <tr>
        <td class="btbg2">社区用房</td>
        <td colspan="5">
          {{form.SQYF}}
        </td>
      </tr>
      <tr>
        <td class="btbg2">养老服务用房</td>
        <td colspan="5">{{form.YLFWYF}}</td>
      </tr>
      <tr>
        <td class="btbg2">市政依托</td>
        <td colspan="5">{{form.SZYT}}</td>
      </tr>
      <tr>
        <td class="btbg2">其他</td>
        <td colspan="5">{{form.QT3}}</td>
      </tr>
      <tr>
        <td colspan="6" class="wrap-zs" style="height:100px!important;">注：{{form.ZHU3}}</td>
      </tr>
    </table>
    <div style="page-break-after: always"></div>
    <table width="100%" cellspacing="1" cellpadding="4" class="tabtop13">
       <!-- 第四部分 -->
      <tr>
        <td rowspan="10" class="btbg1 font-center" style="width: 30px" >
          <div>四、交通组织</div>
        </td>
        <td class="btbg2" rowspan="2">出入口设置</td>
        <td colspan="5">机动车主要出入口：{{form.CRKSZ1}}</td>
      </tr>
      <tr>
        <td colspan="5">人行出入口：{{form.CRKSZ2}}</td>
      </tr>
      <tr>
        <td class="btbg2" rowspan="5">停车泊位</td>
        <td colspan="5">小型汽车停车位：{{form.TCBW1}}</td>
      </tr>
      <tr>
        <td colspan="5">上落客货车停车位：{{form.TCBW2}}</td>
      </tr>
      <tr>
        <td colspan="5">非机动车停车位：{{form.TCBW3}}</td>
      </tr>
      <tr>
        <td colspan="5">地面停车位数量要求：{{form.TCBW4}}</td>
      </tr>
      <tr>
        <td colspan="5" class="wrap-zs" style="height:100px!important;">注：{{form.ZHU4}}</td>
      </tr>
     <tr>
        <td class="btbg2">停车方式</td>
        <td colspan="4">{{form.TCFS}}</td>
      </tr>
      <tr>
        <td class="btbg2">交通组织</td>
        <td colspan="5">{{form.JTZZ}}</td>
      </tr>
      <tr>
        <td class="btbg2">交通分析</td>
        <td colspan="5">{{form.JTFX}}</td>
      </tr>
      <!-- 第五部分 -->
      <tr>
        <td rowspan="4" class="btbg1 font-center"><div>五、城市设计要求</div></td>
        <td class="btbg2" style="height:170px;">建筑设计</td>
        <td colspan="5">
          <span  v-for="(item,index) in (form.JZSJ||'').split('\n')" :key="index">{{item}}<br></span>
        </td>
      </tr>
      <tr>
        <td class="btbg2">城市设计</td>
        <td colspan="5">{{form.CSSJ}}</td>
      </tr>
      <tr>
        <td class="btbg2">专项设计</td>
        <td colspan="5">{{form.ZXSJ}}</td>
      </tr>
      <tr>
        <td class="btbg2" style="height:150px;">环境景观</td>
        <td colspan="5">
           <span v-for="(item,index) in (form.HJJG||'').split('\n')" :key="index">{{item}}<br></span>
         </td>
      </tr>
    </table>
    <div style="page-break-after: always"></div>
    <table width="100%" cellspacing="1" cellpadding="4" class="tabtop13">
      <!-- 第六部分 -->
      <tr>
        <td rowspan="1"  class="btbg1 font-center" style="width: 30px"><div>六、其他要求</div></td>
        <td colspan="6">
           <span v-for="(item,index) in (form.QTYQ||'').split('\n')" :key="index">{{item}}<br></span>
        </td>
      </tr>
      <!-- 第七部分 -->
     <tr>
        <td class="btbg1 font-center" style="height:140px!important;"><div>七、注意事项</div></td>
        <td colspan="6">
           <span v-for="(item,index) in (form.ZYSX||'').split('\n')" :key="index">{{item}}<br></span>
        </td>
      </tr>
      <!-- 第八部分 -->
      <tr>
        <td class="btbg1 font-center" style="height:140px!important;"><div>八、附件</div></td>
        <td colspan="6">
           <span v-for="(item,index) in (form.FJ||'').split('\n')" :key="index">{{item}}<br></span>
        </td>
      </tr>
    </table>
    <div class="wrap-p">
      <p>慈溪市自然资源和规划局</p>
      <p>{{getDate(form.JBRQ)}}</p>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  data() {
    return {
      ZBH: '',
      nowTime:'',
      HHXZ:[]
    }
  },
  props: {
    form: {
      type: Object,
    },
    YDXZ: {
      type: String,
    },

  },
  methods: {
    checkH(){
      if (this.form.HHYDXZXZ3 != null && this.form.HHYDXZXZ3 != undefined) {
        this.HHXZ = [
          { title: this.form.HHYDXZXZ1, value: this.form.HHYDXZBL1 },
          { title: this.form.HHYDXZXZ2, value: this.form.HHYDXZBL2 },
          { title: this.form.HHYDXZXZ3, value: this.form.HHYDXZBL3 },
        ]
        return 3;
      } else if (this.form.HHYDXZXZ2 != null && this.form.HHYDXZXZ2 != undefined) {
        this.HHXZ = [
          { title: this.form.HHYDXZXZ1, value: this.form.HHYDXZBL1 },
          { title: this.form.HHYDXZXZ2, value: this.form.HHYDXZBL2 },
        ]
        return 2;
      } else if (this.form.HHYDXZXZ1 != null && this.form.HHYDXZXZ1 != undefined) {
        this.HHXZ = [{ title: this.form.HHYDXZXZ1, value: this.form.HHYDXZBL1 }]
        return 1;
      } else {
        this.HHXZ = [{ title: this.form.HHYDXZXZ1, value: this.form.HHYDXZBL1 }]
        return 1;
      }
    },
    getDate(date) {
      var a = date
      a= dayjs(date).format('YYYY-MM-DD')
      return a
    },
  },
  mounted() {
    let r=this.$router.routeTags
    if(r!=null&&r!=undefined){
    for(let i=0;i<r.length;i++){
      if(r[i].meta.Id&&r[i].meta.Id == this.$route.query.instanceId) {
         this.ZBH=r[i].meta.title
      }
    }
    }
    this.getDate()
  },
}
</script>

<style scoped>

.tabtop13 td {
  background-color: #ffffff;
  padding: 0 8px;
}

.font-center {
  text-align: center;
  height: 300px!important;
}
.font-center div{
  margin: 0 auto;
  width: 20px;
}
.btbg1 {
  font-weight: bold;
}

.btbg2 {
  font-weight: bold;
  width: 100px;
  text-align: center;
}
.wrap {
  width: 100%;
  margin: 0 auto;
  display: none;
}

.wrap-p {
  float: right;
}
.wrap-underline {
  display: inline-block;
  /* text-decoration: underline; */
  text-align: center;
  min-width: 40px;
  border-bottom: 1px solid #000000;
}
sup {
  font-size: 12px;
}
.zi-center {
  font-weight: bold;
  text-align: center;
}
.wrap table {
  /* font-size: 2em; */
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #000000;
}
.wrap table td {
  border: 1px solid #000000;
  height: 40px;
}
.wrap-zs{
  height: 60px !important;
}
.title-width{
  width: 90px!important;
}
</style>